<template>
  <router-link class="back-button" :to="to">
      <svg class="icn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>
  </router-link>
</template>

<script>
export default {
    name: 'close',
    props: {
        to: {
            type: String,
            default: '/dashboard'
        },
    },
}
</script>

<style lang="scss">
.back-button {
    position: absolute;
    top: -20px;
    left: 40px;
    width: 40px;
    height: 40px;
    background-color: white !important;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.4s;
    transform: translate(0%, 0px);

    .icn {
        transform: rotate(180deg) scale(0.7);
        opacity: 0.6;
        transition: all ease-in-out 0.4s;
    }

    &:hover {
        transform: translate(0%, -10px);

        .icn {
            transform: rotate(180deg);
        }
    }
}
</style>